<template>
	<div class="Navbox">
		<div class="header-navbox">
			<img @click="operateNav" src="~@/assets/images/english/mob/part1/nav.png" alt="" />
			<nav class="header-nav" :class="{ activeNav: isActive }" @click="closeNav">
				<button class="base-close-button" @click="operateNav"></button>
				<ul class="header-nav__ul">
					<li class="header-nav_border">
						<div class="header-nav__li header-nav__link" @click="$parent.scrollToComponent('Home')">
							{{ nav.navTitle1 }}
						</div>
					</li>
					<!-- <li class="header-nav_border">
						<div class="header-nav__li header-nav__link" @click="$parent.scrollToComponent('Register')">
							SIGN UP TO GET REWARDS
						</div>
					</li> -->
				<!-- 	<li class="header-nav_border">
						<div class="header-nav__li header-nav__link" @click="$parent.scrollToComponent('Milestone')">
							{{ nav.navTitle2 }}
						</div>
					</li> -->
					<li class="header-nav_border">
						<div class="header-nav__li header-nav__link" @click="$parent.scrollToComponent('Characters')">
							{{ nav.navTitle3 }}
						</div>
					</li>
					<li class="header-nav_border">
						<div class="header-nav__li header-nav__link" @click="$parent.scrollToComponent('Showcase')">
							{{ nav.navTitle4 }}
						</div>
					</li>
					<li class="header-nav_border">
						<div class="header-nav__img ">
							<a href="https://apps.apple.com/us/app/tokyo-ghoul-break-the-chains/id6472877362"
								target="app">
								<img :src="require(`@/assets/images/${language}/apple.png`)" alt="">
							</a>
						</div>
					</li>
					<li class="header-nav_border">
						<div class="header-nav__img ">
							<a href="https://play.google.com/store/apps/details?id=com.funcat.tgbtcar&hl=en_US"
								target="google">
								<img :src="require(`@/assets/images/${language}/google.png`)" alt="">
							</a>
						</div>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {
				isActive: false, //是否展示导航栏
			};
		},
		computed: {
			...mapState(["language", "nav"]), // 在这里引入你需要的state值
		},
		methods: {
			// 点击app下载按钮跳出弹框
			openApp() {
				this.$bus.$emit('openApp', )
			},
			// 点击google下载按钮跳出弹框
			openGoogle() {
				this.$bus.$emit('openGoogle')
			},
      //点击导航栏按钮，导航内容进行显示或隐藏
			operateNav() {
				this.isActive = !this.isActive;
			},
			//点击非导航栏区域，导航内容进行隐藏
			closeNav() {
				if (!event.target.closest(".header-nav__ul")) {
					this.isActive = false
				}
			}
		},
		mounted() {}
	};
</script>

<style lang="less" scoped>
	.header-navbox {
		position: fixed;
		right: 0;
		z-index: 99;
		top: 5%;

		.activeNav {
			bottom: 0;
			left: 0;
			position: fixed;
			right: 0;
			top: 0;
			transform: translateX(0);
			transition: transform 0.2s ease-in-out;
		}
	}

	.header-nav {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		transform: translateX(100%);
		transition: transform 0.2s ease-in-out;

		.base-close-button {
			background: url(~@/assets/images/english/pc/part1/nav2.png) center no-repeat;
			position: absolute;
			right: 30%;
			top: 5%;
			background-size: cover;
			height: 183px;
			width: 50px;
		}

		.header-nav__ul {
			background-color: #000;
			color: #ebeae8;
			display: flex;
			flex-direction: column;
			height: 100%;
			margin-left: auto;
			overflow-x: auto;
			width: 30%;
			padding-top: 75px;

			.header-nav__li {
				cursor: pointer;
				text-align: left;
				padding: 3% 0 3% 10%;

				&:hover {
					background: url(~@/assets/images/english/pc/part1/nav-button.png) center no-repeat;
					background-size: 100% 100%;
				}

			}

			.header-nav_border {
				padding: 0 5%;
				margin: 5% 0;

				.header-nav__link {
					color: inherit;
					font-size: 30px;
					font-weight: 600;
				}

				.header-nav__img {
					cursor: pointer;
					text-align: left;
					padding: 0 0 3% 10%;
				}

			}

		}

	}

	button {
		background-color: unset;
		border: none;
		display: inline-block;
	}

	a,
	button,
	img {
		cursor: pointer;
	}

	@media screen and (max-width: 980px) {
		.Navbox {
			position: fixed;
			z-index: 99;

			.header-nav {
				bottom: 0;
				left: 10px;
				position: fixed;
				right: 0;
				top: 0;
				transform: translateX(100%);
				transition: transform 0.2s ease-in-out;
			}

			.header-navbox {
				position: absolute;
				left: 576px;
				z-index: 11;
				top: 25px;

				.activeNav {
					bottom: 0;
					left: 0;
					position: fixed;
					right: 0;
					top: 0;
					transform: translateX(0);
					transition: transform 0.2s ease-in-out;
				}

				.header-nav__ul {
					background-color: #000;
					color: #ebeae8;
					display: flex;
					flex-direction: column;
					height: 100%;
					margin-left: auto;
					overflow-x: auto;
					width: 30%;
					padding-top: 280px;

					.header-nav__li {
						cursor: pointer;
						text-align: left;
						display: flex;
						align-items: flex-start;
						padding: 4% 4%;
					}

					.header-nav__img {
						cursor: pointer;
						padding: 0 0 0 0;
						transform: scale(0.6);
						margin-right: 200px;

					}

					.header-nav__link {
						color: inherit;
						font-size: 22px;
						font-weight: 600;
					}
				}
			}
		}
	}
</style>