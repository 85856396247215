<template>
	<div>
		<div class="header pc">
			<div class="header-img">
				<a href="https://www.facebook.com/facebook.tgbtc.ar" target="facebook">
					<img src="~@/assets/images/english/pc/part1/facebook.png" alt="">
				</a>
			</div>
			<div class="header-img">
				<a href="https://twitter.com/tgbtc_ar" target="x">
					<img src="~@/assets/images/english/pc/part1/x.png" alt="">
				</a>
			</div>
			<div class="header-img">
				<a href="https://discord.gg/KtrJZAMBAn" target="discord">
					<img src="~@/assets/images/english/pc/part1/discord.png" alt="">
				</a>
			</div>
	
			<div class="header-img">
				<audio ref="audio" loop src="~@/assets/images/english/pc/part1/music.wav"></audio>
				<img :class="{ 'rotate': isRotate }" class="music" @click="playMusic"
					src="~@/assets/images/english/pc/part1/music.png" alt="">
			</div>
		</div>
		<div class="header yd">
			<div class="header-img">
				<a href="https://twitter.com/tgbtc_ar" target="X">
					<img src="~@/assets/images/english/mob/part1/X.png" alt="">
				</a>
			</div>
			<div class="header-img">
				<a href="https://www.facebook.com/facebook.tgbtc.ar" target="facebook">
					<img src="~@/assets/images/english/mob/part1/facebook.png" alt="">
				</a>
			</div>
			<div class="header-img">
				<a href="https://discord.gg/KtrJZAMBAn" target="discord">
					<img src="~@/assets/images/english/mob/part1/discord.png" alt="">
				</a>
			</div>
	
			<div class="header-img">
				<audio ref="audio" loop src="~@/assets/images/english/pc/part1/music.wav"></audio>
				<img :class="{ 'rotate': isRotate }" @click="playMusic"
					src="~@/assets/images/english/pc/part1/music.png" alt="">
			</div>
	
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isRotate: false
			};
		},
		components: {},
		computed: {},
		methods: {
			// 点击音乐按钮播放音乐
			playMusic() {
				this.isRotate = !this.isRotate;
				if (this.$refs.audio.paused) {
					this.$refs.audio.play();
				} else {
					this.$refs.audio.pause();
				}

			}
		},
		mounted() {},
	};
</script>

<style lang="less" scoped>
	@import "../../assets/css/definition.less";

	.header {
		.height(560rem);
		width: @width;
		position: relative;
		.top(900rem);
		.left(240rem);
		z-index: 9;

		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}

			to {
				transform: rotate(360deg);
			}
		}

		.rotate {
			animation: rotate 2s infinite linear;
		}

		.header-img {
			float: left;
			cursor: pointer;
			.margin(0, 390rem, 0, 380rem);

			.music {
				.width(550rem);
				.margin(0, 0, 0, 800rem);
			}

			.tiktok {
				.width(500rem);
				.height(500rem);
			}
		}
	}

	@media screen and (max-width: 980px) {
		@import "../../assets/css/mDefinition.less";

		.header {
			.top(90rem);
			.left(0);

			.header-img {
				float: left;
				cursor: pointer;
				transform: scale(0.9);
				.margin(0, 80rem, 0, 80rem);

				&:nth-child(4) {
					.left(40rem);
					position: absolute;
					.top(400rem);
				}

				&:nth-child(5) {
					.left(45rem);
					position: absolute;
					.top(465rem);
				}

				&:nth-child(6) {
					left: 225px;
					position: absolute;
					.top(430rem);
				}

				.tiktok {

					.width(360rem);
					.height(360rem);
				}
			}


		}



	}
</style>