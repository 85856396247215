import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import './assets/css/style.css'
// import {zoomChange} from './assets/js/common.js'
import {addCookie, getCookie, delCookie} from './assets/js/cookie.js';
Vue.prototype.$bus = new Vue()
import 'swiper/css/swiper.css'
import router from './router'
import store from './store'

Vue.prototype.$cookieStore = {
  addCookie,
  getCookie,
  delCookie
}



Vue.config.productionTip = false
Vue.prototype.axios = axios;
new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')




// function handleWindowSize() {
//   var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

//   if (screenWidth < 620) {
//     // 当浏览器宽度小于620时，调用的方法
//     // 在这里执行你想要调用的方法
//     zoomChangeyd()
//   }
// }

// // 在页面加载时和窗口大小变化时调用handleWindowSize方法
// window.onload = handleWindowSize;
// window.onresize = handleWindowSize;