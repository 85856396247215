<template>
  <div>
    <div class="gamesamba_header pc">
      <div class="w1302">
        <a href="https://www.gamesamba.com" target="home">
          <img
            class="logo"
            alt="logo"
            src="https://e112ddb-a.akamaihd.net/assets/v20161205/images/gs_logo.png?version=3"
        /></a>
        <ul id="nav_main">
          <li><a href="https://www.gamesamba.com" target="home"> HOME </a></li>
          <li>|</li>
          <li>
            <a href="https://www.gamesamba.com/site/games" class="" target="games">
              GAMES <em></em
            ></a>
            <div
              class="nav_show nav_show2"
              style="
                width: 635px;
                display: none;
                height: 179.667px;
                padding-top: 0px;
                margin-top: 0px;
                padding-bottom: 0px;
                margin-bottom: 0px;
              "
            >
              <div class="mobile_game">
                <div>
                  <span style="font-weight: bold; color: #fff"
                    ><i>
                      <img
                        src="https://e112ddb-a.akamaihd.net/assets/v20161205/images/nav_icon/nav_icon_01.png?version=1" /></i
                    >Mobile Games</span
                  >
                </div>
                <div>
                  <a href="https://naruto.gamesamba.com/"
                    ><i
                      ><img
                        src="https://e112ddb-a.akamaihd.net/upload/manage/banner/1609313026506.png" /></i
                    >NARUTO: SLUGFEST</a
                  >
                </div>
                <div>
                  <a href="http://tg2.gamesamba.com/"
                    ><i
                      ><img
                        src="https://e112ddb-a.akamaihd.net/upload/manage/banner/1481010898880.png" /></i
                    >Tokyo Ghoul: Break the Chains</a
                  >
                </div>
              </div>
              <div class="browser_game">
                <div>
                  <span style="font-weight: bold; color: #fff"
                    ><i
                      ><img
                        src="https://e112ddb-a.akamaihd.net/assets/v20161205/images/nav_icon/nav_icon_07.png?version=1" /></i
                    >Browser Games</span
                  >
                </div>
                <div>
                  <a href="http://startrek.gamesamba.com/"
                    ><i
                      ><img
                        src="https://e112ddb-a.akamaihd.net/upload/manage/banner/1481016245138.png" /></i
                    >Star Trek™: Alien Domain</a
                  >
                </div>
                <div>
                  <a href="https://incursion.gamesamba.com/"
                    ><i
                      ><img
                        src="https://e112ddb-a.akamaihd.net/upload/manage/banner/1542093846497.png" /></i
                    >Star Trek™ Alien Domain: Incursion</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li>|</li>
          <li>
            <a href="https://www.gamesamba.com/site/list" target="news">
              NEWS <em></em
            ></a>
          </li>
          <li>|</li>
          <li>
            <a href="https://support.gamesamba.com/" target="support" class="">
              SUPPORT
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="header yd"></div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      isRotate: false,
    };
  },
  created: function () {
    console.log($("#app"));

    // $("nav#menu").mmenu();
    $("#nav_main li").hover(
      function () {
        $(this).find(".nav_show").slideDown(200);
        $(this).children("a").addClass("cur").siblings().removeClass("cur");
      },
      function () {
        $(this).find(".nav_show").stop().slideUp("fast");
        $(this).children("a").removeClass("cur");
      }
    );

    // $(function(){
    //   $("nav#menu").mmenu();
    //     $("#nav_main li").hover(
    //       function () {
    //         $(this).find(".nav_show").slideDown(200);
    //         $(this).children("a").addClass("cur").siblings().removeClass("cur");
    //       },
    //       function () {
    //         $(this).find(".nav_show").stop().slideUp("fast");
    //         $(this).children("a").removeClass("cur");
    //       }
    //     );

    //     $(".meun_ul li.arrow").click(function () {
    //       $(this).toggleClass("arrow_bottom").siblings().removeClass("arrow_bottom");
    //       $(this)
    //         .find(".game_open")
    //         .slideToggle()
    //         .parent()
    //         .siblings()
    //         .find(".game_open")
    //         .slideUp();
    //     });
    //     $(".fdiv").hide();
    //     $(".jian").click(function () {
    //       $(this).toggleClass("jian2");
    //       $(".fdiv").slideToggle();
    //     });

    // })
  },
  components: {},
  computed: {},
  methods: {
    // 点击音乐按钮播放音乐
    playMusic() {
      this.isRotate = !this.isRotate;
      if (this.$refs.audio.paused) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/definition.less";

/* header */
.gamesamba_header {
  width: 100%;
  height: 78px;
  background: #000;
  position: fixed;
  z-index: 99;
  top: 0%;
}
.gamesamba_header .w1302 {
  position: relative;
  left: 17%;
}
.gamesamba_header .logo {
  display: block;
  width: 172px;
  height: 50px;
  padding: 13px 0;
  float: left;
}
.gamesamba_header ul {
  float: left;
  padding: 0 0 0 35px;
}
.gamesamba_header ul li {
  float: left;
  color: #2d2d2d;
  margin: 0 5px;
  line-height: 76px;
  position: relative;
}
.gamesamba_header ul li a {
  display: block;
  color: #bbbaba;
  font-size: 20px;
  height: 76px;
  line-height: 76px;
  position: relative;
}
.gamesamba_header ul li a em {
  position: absolute;
  top: 58px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #000;
}
.gamesamba_header ul li a.cur {
  color: #fff;
}
.gamesamba_header ul li a.cur em {
  border-top: 6px solid #fff;
}

@media screen and (max-width: 980px) {
  @import "../../assets/css/mDefinition.less";

  .header {
    .top(90rem);
    .left(0);

    .header-img {
      float: left;
      cursor: pointer;
      transform: scale(0.9);
      .margin(0, 80rem, 0, 80rem);

      &:nth-child(4) {
        .left(40rem);
        position: absolute;
        .top(400rem);
      }

      &:nth-child(5) {
        .left(45rem);
        position: absolute;
        .top(465rem);
      }

      &:nth-child(6) {
        left: 225px;
        position: absolute;
        .top(430rem);
      }

      .tiktok {
        .width(360rem);
        .height(360rem);
      }
    }
  }
}
</style>
