var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"part-main"},[_c('div',{staticClass:"part4 pc",style:({ 'background-image': `url(${_vm.imageUrl})` })},[_c('video',{attrs:{"src":require("@/assets/images/english/smooth.mp4"),"loop":"","autoplay":"","muted":""},domProps:{"muted":true}}),_c('videoPopup',{ref:"videoPopup"}),_c('div',{staticClass:"swiper",attrs:{"id":"character-pcswiper"}},[_c('div',{staticClass:"swiper-wrapper",attrs:{"id":"swiper-wrapper"}},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"unexpansion",attrs:{"src":require(`@/assets/images/${_vm.language}/pc/part4/unexpansion/character${
                  index + 1
                }.png`),"alt":""}}),_c('div',{staticClass:"expansion"},[_c('p',{staticClass:"text",class:{ 'alabo-text': _vm.language === 'alabo' }},[_vm._v(" "+_vm._s(_vm.charactersText(index))+" ")]),_c('div',{staticClass:"audio"},[(index !== 1)?_c('video',{ref:'video' + index,refInFor:true,staticClass:"introduction",attrs:{"id":"video","muted":"muted","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require(`@/assets/images/introduction${index + 1}.mp4`),"type":"video/mp4"}})]):_vm._e(),(index !== 1)?_c('img',{staticClass:"play",attrs:{"src":require("@/assets/images/english/pc/part4/play.png"),"alt":""},on:{"click":function($event){return _vm.playVideo(index)}}}):_vm._e(),(index === 1)?_c('img',{attrs:{"src":require("@/assets/images/english/pc/part4/coming.png"),"alt":""}}):_vm._e()]),_c('img',{attrs:{"src":require(`@/assets/images/${_vm.language}/pc/part4/expansion/character${
                    index + 1
                  }.png`),"alt":""}})])])}),0)]),_c('div',{staticClass:"swiper-button-next",attrs:{"id":"character-pcswiper-button-next"}},[_c('img',{staticClass:"fade-in-out",attrs:{"src":require("@/assets/images/english/pc/part4/arrow1.png")}}),_c('img',{class:{ 'fade-in-out': _vm.isAnimating2 },attrs:{"src":require("@/assets/images/english/pc/part4/arrow2.png")}}),_c('img',{class:{ 'fade-in-out': _vm.isAnimating3 },attrs:{"src":require("@/assets/images/english/pc/part4/arrow3.png")}})]),_c('div',{staticClass:"swiper-button-prev",attrs:{"id":"character-pcswiper-button-prev"}},[_c('img',{staticClass:"fade-in-out",attrs:{"src":require("@/assets/images/english/pc/part4/arrow1.png")}}),_c('img',{class:{ 'fade-in-out': _vm.isAnimating2 },attrs:{"src":require("@/assets/images/english/pc/part4/arrow2.png")}}),_c('img',{class:{ 'fade-in-out': _vm.isAnimating3 },attrs:{"src":require("@/assets/images/english/pc/part4/arrow3.png")}})])],1),(_vm.isWideScreen)?_c('div',{staticClass:"yd"},[_c('videoPopup',{ref:"videoPopup"}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require(`@/assets/images/${_vm.language}/mob/part4/title.png`),"alt":""}})]),_c('div',{staticClass:"swiper mySwiper2"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{attrs:{"src":require(`@/assets/images/${_vm.language}/mob/part4/interface/character${
                    index + 1
                  }.png`)}}),_c('p',{staticClass:"expansion text",class:{ 'alabo-text': _vm.language === 'alabo' }},[_vm._v(" "+_vm._s(_vm.charactersText(index))+" ")]),_c('div',{staticClass:"expansion audio"},[(index !== 1)?_c('video',{ref:'videoyd' + index,refInFor:true,staticClass:"introduction",attrs:{"muted":"true","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":require(`@/assets/images/introduction${index + 1}.mp4`),"type":"video/mp4"}})]):_vm._e(),(index !== 1)?_c('img',{staticClass:"play",attrs:{"src":require("@/assets/images/english/mob/part4/play.png"),"alt":""},on:{"click":function($event){return _vm.playVideo(index)}}}):_vm._e(),(index === 1)?_c('img',{attrs:{"src":require("@/assets/images/english/mob/part4/coming.png"),"alt":""}}):_vm._e()])])}),0)]),_c('div',{staticClass:"swiper mySwiper",attrs:{"thumbsSlider":""}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((8),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('img',{staticClass:"unexpansion",attrs:{"src":require(`@/assets/images/english/mob/part4/unexpansion/character${
                    index + 1
                  }.png`)}}),_c('img',{staticClass:"expansion active",attrs:{"src":require(`@/assets/images/english/mob/part4/expansion/character${
                    index + 1
                  }.png`)}}),_c('img',{staticClass:"expansion checked",attrs:{"src":require("@/assets/images/english/mob/part4/checked.png"),"alt":""}})])}),0)])])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }