<template>
  <div class="box">
    <div class="part-main">
      <div
        class="part5 pc"
        @mousemove="handleMouseMove"
        :style="{ 'background-image': `url(${imageUrl})` }"
      >
        <video src="~@/assets/images/english/smooth.mp4" loop autoplay muted></video>
        <!-- 轮播图 -->
        <div class="swiper-container" ref="bannerBox" id="showcase-pcswiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in 4" :key="index">
              <img
                :src="
                  require(`@/assets/images/${language}/pc/part5/banner${index + 1}.jpg`)
                "
              />
              <!-- <img class="text"
								:src="require(`@/assets/images/${language}/pc/part5/text${index+1}.png`)" /> -->
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
          <div class="swiper-button-prev">
            <img src="~@/assets/images/english/pc/part4/arrow1.png" class="fade-in-out" />
            <img
              src="~@/assets/images/english/pc/part4/arrow2.png"
              :class="{ 'fade-in-out': isAnimating2 }"
            />
            <img
              src="~@/assets/images/english/pc/part4/arrow3.png"
              :class="{ 'fade-in-out': isAnimating3 }"
            />
          </div>
          <div class="swiper-button-next">
            <img src="~@/assets/images/english/pc/part4/arrow1.png" class="fade-in-out" />
            <img
              src="~@/assets/images/english/pc/part4/arrow2.png"
              :class="{ 'fade-in-out': isAnimating2 }"
            />
            <img
              src="~@/assets/images/english/pc/part4/arrow3.png"
              :class="{ 'fade-in-out': isAnimating3 }"
            />
          </div>
        </div>
      </div>

      <div class="yd">
        <!-- 标题 -->
        <div class="title">
          <img :src="require(`@/assets/images/${language}/mob/part5/title.png`)" alt="" />
        </div>
        <!-- 轮播图 -->
        <div class="swiper-container" id="showcase-ydswiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in 4" :key="index">
              <img
                :src="
                  require(`@/assets/images/${language}/mob/part5/banner${index + 1}.png`)
                "
                alt=""
              />
              <!-- 	<img class="text" :src="require(`@/assets/images/${language}/mob/part5/text${index+1}.png`)"
								:class="{englishText:language==='english'}" alt="" /> -->
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import { mapState } from "vuex";
export default {
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      isAnimating2: false,
      isAnimating3: false,
      imageUrl: "", // 存储背景图片的URL
      // language: 'alabo'
    };
  },
  components: {},
  computed: {
    ...mapState(["language"]), // 在这里引入你需要的state值
  },
  methods: {
    // 页面加载时会触发,并给箭头添加动画效果
    toggleAnimation2() {
      this.isAnimating2 = !this.isAnimating2;
    },
    // 页面加载时会触发,并给箭头添加动画效果
    toggleAnimation3() {
      this.isAnimating3 = !this.isAnimating3;
    },
    // 当鼠标移动,轮播图会随鼠标方向扭曲移动
    handleMouseMove(event) {
      this.$nextTick(() => {
        // 获取鼠标相对于轮播图容器的位置
        const containerRect = this.$el.getBoundingClientRect();
        this.mouseX = event.clientX - containerRect.left;
        this.mouseY = event.clientY - containerRect.top;
        // 根据鼠标位置计算扭曲效果的参数
        const maxDistort = 70; // 最大扭曲程度
        const maxRotation = 10; // 最大旋转角度
        const percentX = (this.mouseX / containerRect.width) * 2 - 1;
        const percentY = (this.mouseY / containerRect.height) * 2 - 1;
        const distortX = maxDistort * percentX;
        const distortY = maxDistort * percentY;
        const rotateY = maxRotation * percentX;
        const rotateX = -maxRotation * percentY;
        // 应用扭曲效果的样式
        this.$refs.bannerBox.style.transform = `perspective(1000px) translate3d(${distortX}px, ${distortY}px, 0) rotateY(${rotateY}deg) rotateX(${rotateX}deg)  translateX(0px)`;
      });
    },
  },
  watch: {},
  mounted() {
    setTimeout(this.toggleAnimation2, 1300); // 每1.3秒切换动画状态
    setTimeout(this.toggleAnimation3, 1000); // 每1秒切换动画状态
    // if (this.$cookieStore.getCookie('language') && this.$cookieStore.getCookie('language') != undefined) {
    // 	this.language = this.$cookieStore.getCookie('language');
    // }
    this.$nextTick(() => {
      // 根据语言来设置背景图片的URL
      this.imageUrl = require(`@/assets/images/${this.language}/pc/part5/bg5.jpg`);

      new Swiper("#showcase-pcswiper", {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: "#showcase-pcswiper .swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: "#showcase-pcswiper .swiper-button-next",
          prevEl: "#showcase-pcswiper .swiper-button-prev",
        },
        // swiper的配置项
        on: {
          slideChange: function () {},
        },
      });

      new Swiper("#showcase-ydswiper", {
        slidesPerView: "auto",
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: "#showcase-ydswiper .swiper-pagination",
          clickable: true,
        },
        // swiper的配置项
        on: {
          slideChange: function () {},
        },
      });
    });
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 980px) {
  .part5 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;

    .swiper-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
      transition: transform 0.3s;
      transform-style: preserve-3d;
    }
  }

  .swiper-slide {
    backface-visibility: hidden;
    will-change: transform;
    backface-visibility: hidden;
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8);
    z-index: 1;
    position: relative;
    bottom: 5%;
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    transform: scale(1.36);
    z-index: 2;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  .swiper-slide-next {
    transform: translate(-50%, 5%) scale(0.8);
  }

  .swiper-slide-prev {
    transform: translate(50%, 5%) scale(0.8);
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 204px;
    height: 136px;
    background-size: 100% 100%;
    top: 43%;
    transform: scale(0.6);
  }

  .swiper-button-next {
    right: 11.5%;
    margin-bottom: 210px;
    transform: scale(0.6) rotateY(180deg);
  }

  .swiper-button-prev {
    left: 11.5%;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: "";
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 310px;
    left: 0;
    width: 100%;
  }

  .swiper-slide .text {
    position: absolute;
    bottom: 16%;
    opacity: 0;
  }

  .swiper-slide-active .text {
    opacity: 1;
    transition: opacity 1.3s ease-in-out;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fadeInOut 1.5s infinite;
}

@media screen and (max-width: 980px) {
  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .part-main {
    height: 1000px;
  }

  .yd .title {
    transform: translate(-9%) scale(0.8);
    margin-bottom: -6%;
  }

  .swiper-container {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
    transform-style: preserve-3d;
  }

  .swiper-slide {
    backface-visibility: hidden;
    will-change: transform;
    backface-visibility: hidden;
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.8);
    z-index: 1;
    position: relative;
    bottom: 33%;
	left: -13%;
  }

  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    z-index: 2;
  }

  .swiper-slide img {
    display: block;
    width: 92.2%;
    object-fit: cover;
    transform: scale(1.15) translate(-110%);
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 1;
    cursor: pointer;
    pointer-events: auto;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    content: "";
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 61%;

    width: 100%;
  }

  .description-text {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    bottom: 10%;
    width: 980px;
    height: 160px;
  }

  .swiper-slide .text {
    position: absolute;
    bottom: 0;
    width: 980px;
    transform: scale(0.75);
    opacity: 0;
    left: -17.7%;
    margin-bottom: 1%;
  }

  .swiper-slide-active .text {
    opacity: 1;
    transition: opacity 1.3s ease-in-out;
  }

  .swiper-pagination {
    /* position:static; */
    text-align: left;
    margin-left: 250px;
  }

  .swiper-wrapper {
    height: 1080px;
  }

  .englishText {
    transform: scale(0.8) !important;
  }
}
</style>
