<template>
	<!-- 遮罩层 -->
	<div class="mask" v-if="showVideo">
		<div class="pre_con">
			<div class="q1">
				<video class="video" loop autoplay controls>
					<source :src="require(`@/assets/images/introduction${index+1}.mp4`)" type="video/mp4" />
				</video>
			</div>
			<img data-v-1a69e17a="" src="https://gf2-cn.cdn.sunborngame.com/website/official/pc/img/pre_close.png"
				alt="" class="pre_close" @click="changeVideo" />
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				showVideo: false, //默认是不展示视频弹框
				index: 0
			}
		},
		methods: {
			changeVideo() {
				this.showVideo = !this.showVideo
			}
		}
	}
</script>

<style lang="less" scoped>
	@media screen and (min-width: 981px) {
		
	}
	.mask {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		display: block;

		.pre_con {
			width: 1110px;
			height: 620px;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translateX(-50%) translateY(-50%);
			transform: translateX(-50%) translateY(-50%);

			.pre_close {
				position: absolute;
				right: 0px;
				top: -50px;
				cursor: pointer;
			}

		}
	}

	@media screen and (max-width: 980px) {
		.mask .pre_con {
			
			zoom: 0.5;
			
			>img{
				width: 7%;
			}
			.pre_close {
				top: -100px;
	
			}
		}
		
	}
</style>