<template>
	<div class="footer">
		<!-- 底部信息 -->
		<div class="footer-information">
			<!-- 东鬼图标 -->
			<img class="icon" src="~@/assets/images/icon.png" alt="" />
			<!-- 应用详情信息 -->
			<div class="footer_detail">
				<ul>
					<li><span :class="{'title':language === 'english'}">{{footer.title}}</span>{{footer.text1}}
					</li>
					<li><span :class="{'release': language === 'english'}">{{footer.release}}</span>{{footer.text2}}
					</li>
					<li><span :class="{'genre':language === 'english'}">
							<p v-if="language==='alabo'" style="display: inline-block;">3D</P>{{footer.genre}}
							<p v-if="language==='alabo'" style="display: inline-block;" class="RPG">RPG</P>
						</span>{{footer.text3}}
					</li>
					<li v-if="language==='alabo'">
						<p style="display: inline-block;">iOS/</p>{{footer.text4}}<span
							class="platforms">{{footer.platforms}}</span>
					</li>
					<li v-if="language==='english'"><span>{{footer.platforms}}</span>{{footer.text4}}</li>
				</ul>
			</div>
			<!-- 其他内容 -->
			<div class="other_content">
				<!-- other_icon -->
				<div class="other_icon">
					<a href="https://www.facebook.com/facebook.tgbtc.ar" target="facebook">
						<img src="~@/assets/images/english/pc/part1/facebook.png" alt="" /></a>
					<a href="https://twitter.com/tgbtc_ar" target="x">
						<img src="~@/assets/images/english/pc/part1/x.png" alt="" /></a>
					<a href="https://discord.gg/KtrJZAMBAn" target="discord"><img src="~@/assets/images/english/pc/part1/discord.png" alt="" /></a>
				
				</div>
				<!-- other_detail -->
				<div class="other_detail">
					<ul>
						<li>
							<a href="/privacy" target="privacy">{{footer.Policy}}</a>
						</li>
						<li>
							<a href="/Terms" target="Terms">{{footer.terms}}</a>
						</li>
					</ul>
				</div>
			</div>
			<!-- 授权信息 -->
			<div class="authorization_detail">
				<p class="gamesamba_logo"> <a href="https://www.gamesamba.com" target="gamesamba">
						<img src="~@/assets/gamesamba_logo.png" alt="" /></a></p>
				<p class="copyright">
					©Shu Ishida/Shueisha, Tokyo Ghoul Production Committee. ©2016
					GameSamba Corporation. ©FunCat Games.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {};
		},
		components: {},
		computed: {
			...mapState(["language", "footer"]), // 在这里引入你需要的state值
		},
		methods: {},
		mounted() {},
	};
</script>

<style lang="less" scoped>
	.footer {
		width: 100%;
		height: 450px;
		background-color: rgb(28, 29, 32);
		position: relative;
		top: 78px;

		.icon {
			width: 150px;
			height: 150px;
			position: absolute;
			top: 12%;
			left: 17%;
		}

		.other_content {
			color: rgb(173, 166, 166);
			position: absolute;
			top: 18%;
			font-size: 18px;
			left: 63%;

			.other_icon {
				margin-bottom: 30px;

				img {
					margin-right: 60px;
				}

				.tiktok {
					width: 42px;
					height: 42px;
				}
			}
		}

		.other_detail {
			ul {
				float: right;
				margin-right: 185px;

				li {
					margin-bottom: 9px;
					margin-left: 6px;
				}
			}
		}

		.footer_detail {
			color: rgb(173, 166, 166);
			position: absolute;
			top: 16%;
			left: 30%;
			font-size: 18px;

			.platforms {
				margin-right: 80px !important;
			}

			li {
				margin: 8px 0;
			}
		}



		.authorization_detail {
			color: rgb(143, 135, 136);
			position: absolute;
			left: 30%;
			top: 60%;
			font-size: 14px;
		}
		.gamesamba_logo {
			position: relative;
			left: 25%;
			width: 75%;
		}
		.copyright {
			position: relative;
			top: 20px;
		}
		
	}

	.footer_detail li:nth-child(1) span {
		margin: 0px 50px;
	}

	.footer_detail li:nth-child(2) span {
		margin: 0px 159px;
	}

	.footer_detail li:nth-child(3) span {
		margin-right: 156px;
	}

	.footer_detail li:nth-child(4) span {
		margin-right: 77px;
	}

	.title {
		margin: 0 120px 0 0px !important;
	}

	.release {
		margin: 0 87px 0 0 !important;
	}

	.genre {
		margin-right: 100px !important;
	}

	.RPG {
		margin-right: 160px;
	}
	@media screen and (max-width: 980px) {
		.footer {
			width: 100%;
			height: 450px;
			background-color: rgb(28, 29, 32);
			position: relative;
			top: 0px;

			.icon {
				width: 100px;
				height: 100px;
				position: absolute;
				top: 10%;
				left: 12%;
			}

			.footer_detail {
				color: rgb(173, 166, 166);
				position: absolute;
				top: 8%;
				left: 42%;
				font-size: 14px;

				.platforms {
					margin-right: 62px !important;
				}

				li {
					margin: 8px 0;
				}
			}

			.other_content {
				color: rgb(173, 166, 166);
				position: absolute;
				top: 36%;
				font-size: 14px;
				left: 7%;

				.other_icon {
					margin-bottom: 30px;
					margin-left: 20px;

					img {
						margin-top: 30px;
						margin-right: 23px;
						transform: scale(0.8);
					}
				}

				ul {
					width: 120px;
					position: absolute;
					top: 30px;
					left: 150%;
					li {
						margin-bottom: 9px;
						margin-left: 6px;
					}
				}

			}

			.authorization_detail {
				color: rgb(143, 135, 136);
				position: absolute;
				left: 4%;
				top: 62%;
				font-size: 13px;
			}
	
			.copyright {

				position: relative;
				margin-left: 6px;
				margin-right: 6px;
				
			}
		}

		.footer_detail li:nth-child(1) span {
			margin: 0px 45px;
		}

		.footer_detail li:nth-child(2) span {
			margin: 0px 106px;
		}

		.footer_detail li:nth-child(3) span {
			margin-right: 110px;
		}

		.footer_detail li:nth-child(4) span {
			margin-right: 70px;
		}

		.title {
			margin: 0 103px 0 0px !important;
		}

		.release {
			margin: 0 78px 0 0 !important;
		}

		.genre {
			margin-right: 91px !important;
		}

		.RPG {
			margin-right: 107px;
		}
	}
</style>