<template>
	<div>
		<div class="yd-bg">
			<Nav ref="nav" class="popup"></Nav>
			<Home id="Home"></Home>
			<!-- <Register  id="Register"></Register> -->
			<!-- <Milestone id="Milestone"></Milestone> -->
			<Characters id="Characters"></Characters>
			<Showcase id="Showcase"></Showcase>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Nav from "./nav.vue";
	import Home from "./home.vue";
	// import Register from "./register.vue";
	// import Milestone from "./milestone.vue";
	import Characters from "./characters.vue";
	import Showcase from "./showcase.vue";
	import Footer from "./gamesamba_footer.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		data() {
			return {};
		},
		components: {
			Home,
			// Register,
			// Milestone,
			Characters,
			Showcase,
			Footer,
			Nav,
		},
		computed: {
			...mapState(["language", "languageData"]), // 在这里引入你需要的state值
		},
		methods: {
			// 导航栏点击滑动到相应组件
			scrollToComponent(componentId) {
				const element = document.getElementById(componentId);
				element.scrollIntoView({
					behavior: "smooth"
				});
			},
			// 调用vuex中的getlanguageData方法，存储languageData数据到state中
			async getlanguageData() {
				await this.$store.dispatch("getlanguageData");
				this.$store.state.home = this.languageData[this.language].home;
				this.$store.state.characters = this.languageData[this.language].characters;
				this.$store.state.footer = this.languageData[this.language].footer;
				this.$store.state.nav = this.languageData[this.language].nav;
			},

			// 调用vuex中的getlistData方法，存储listData数据到state中
			async getlistData() {
				await this.$store.dispatch("getlistData");
			},

		},
		mounted() {
			// 去获取cookie中的language属性值并存储到vuex中的state
			if (this.$cookieStore.getCookie('language') && this.$cookieStore.getCookie('language') != undefined) {
				this.$store.state.language = this.$cookieStore.getCookie('language');
			}

			this.getlanguageData();

			this.getlistData();
		},
	};
</script>

<style lang="less" scoped="scoped">
	@import "../../assets/css/mDefinition.less";
	@media screen and (max-width: 980px) {
		
		.yd-bg {
			background: url(~@/assets/images/english/mob/part1/bg.jpg) top center no-repeat;
			background-size: cover;
			width: @width;
			height:@height;
			position: relative;
		
		}
	}
</style>