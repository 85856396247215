import Vue from 'vue'
import Router from 'vue-router'

import privacy from '@/views/privacy.vue'
import terms from '@/views/terms.vue'
import index from '@/components/web/index.vue'


Vue.use(Router)
function getAbsolutePath () {
    let path = location.pathname
    return path.substring(0, path.lastIndexOf('/') + 1)
  }
export default new Router({
    mode: 'history',
    base:getAbsolutePath(),
  routes: [
    {
      path: '/privacy',
      name: ' privacy',
      component: privacy
    },   
    {
      path: '/',
      name: 'index',
      component: index 
    }, 
    {
      path: '/terms',
      name: ' terms',
      component: terms
    },  

  ]
})
