import Vue from 'vue';
import vuex from 'vuex';
import axios from 'axios';
Vue.use(vuex)

export default new vuex.Store({
	state: {
		privacyData: '',
		termsData: '',
		// language: 'alabo', //默认语言
		language: 'english', //默认语言
		languageData: '',
		home: "",
		characters: "",
		footer: "",
		nav: "",
		listData:""
	},
	mutations: {
		privacyDataFun(state, data) {
			state.privacyData = data;
		},

		languageDataFun(state, data) {
			state.languageData = data;
		},
		listDataFun(state, data) {
			state.listData = data;
		},
	},



	actions: {
		getPrivacy() {

			return new Promise(resolve => {
				axios.get('privacy.json').then(res => {
					this.commit("privacyDataFun", res.data.data);
					resolve(res)
					console.log(111)
				})
			})
		},


		getlanguageData() {

			return new Promise(resolve => {
				axios.get('language.json').then(res => {
					this.commit("languageDataFun", res.data.data);
					resolve(res)
					console.log(222)
				})
			})
		},

		getlistData() {

			return new Promise(resolve => {
				axios.get('list.json').then(res => {
					this.commit("listDataFun", res.data);
					resolve(res)
					console.log(333)
				})
			})
		},


	},
	modules: {}
})