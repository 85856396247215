var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header pc"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"header-img"},[_c('audio',{ref:"audio",attrs:{"loop":"","src":require("@/assets/images/english/pc/part1/music.wav")}}),_c('img',{staticClass:"music",class:{ 'rotate': _vm.isRotate },attrs:{"src":require("@/assets/images/english/pc/part1/music.png"),"alt":""},on:{"click":_vm.playMusic}})])]),_c('div',{staticClass:"header yd"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"header-img"},[_c('audio',{ref:"audio",attrs:{"loop":"","src":require("@/assets/images/english/pc/part1/music.wav")}}),_c('img',{class:{ 'rotate': _vm.isRotate },attrs:{"src":require("@/assets/images/english/pc/part1/music.png"),"alt":""},on:{"click":_vm.playMusic}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://www.facebook.com/facebook.tgbtc.ar","target":"facebook"}},[_c('img',{attrs:{"src":require("@/assets/images/english/pc/part1/facebook.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://twitter.com/tgbtc_ar","target":"x"}},[_c('img',{attrs:{"src":require("@/assets/images/english/pc/part1/x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://discord.gg/KtrJZAMBAn","target":"discord"}},[_c('img',{attrs:{"src":require("@/assets/images/english/pc/part1/discord.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://twitter.com/tgbtc_ar","target":"X"}},[_c('img',{attrs:{"src":require("@/assets/images/english/mob/part1/X.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://www.facebook.com/facebook.tgbtc.ar","target":"facebook"}},[_c('img',{attrs:{"src":require("@/assets/images/english/mob/part1/facebook.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-img"},[_c('a',{attrs:{"href":"https://discord.gg/KtrJZAMBAn","target":"discord"}},[_c('img',{attrs:{"src":require("@/assets/images/english/mob/part1/discord.png"),"alt":""}})])])
}]

export { render, staticRenderFns }