<template>
  <div class="box">
    <div class="part-main">
      <div class="part4 pc" :style="{ 'background-image': `url(${imageUrl})` }">
        <!-- 轮播图 -->
        <video src="~@/assets/images/english/smooth.mp4" loop autoplay muted></video>
        <videoPopup ref="videoPopup"></videoPopup>
        <div class="swiper" id="character-pcswiper">
          <div class="swiper-wrapper" id="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in 8" :key="index">
              <img
                class="unexpansion"
                :src="
                  require(`@/assets/images/${language}/pc/part4/unexpansion/character${
                    index + 1
                  }.png`)
                "
                alt=""
              />
              <div class="expansion">
                <p class="text" :class="{ 'alabo-text': language === 'alabo' }">
                  {{ charactersText(index) }}
                </p>
                <!-- 视频播放 -->
                <div class="audio">
                  <video
                    v-if="index !== 1"
                    id="video"
                    class="introduction"
                    muted="muted"
                    loop
                    autoplay
                    :ref="'video' + index"
                  >
                    <source
                      :src="require(`@/assets/images/introduction${index + 1}.mp4`)"
                      type="video/mp4"
                    />
                  </video>
                  <img
                    class="play"
                    v-if="index !== 1"
                    @click="playVideo(index)"
                    src="~@/assets/images/english/pc/part4/play.png"
                    alt=""
                  />
                  <img
                    v-if="index === 1"
                    src="~@/assets/images/english/pc/part4/coming.png"
                    alt=""
                  />
                </div>
                <img
                  :src="
                    require(`@/assets/images/${language}/pc/part4/expansion/character${
                      index + 1
                    }.png`)
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next" id="character-pcswiper-button-next">
          <img src="~@/assets/images/english/pc/part4/arrow1.png" class="fade-in-out" />
          <img
            src="~@/assets/images/english/pc/part4/arrow2.png"
            :class="{ 'fade-in-out': isAnimating2 }"
          />
          <img
            src="~@/assets/images/english/pc/part4/arrow3.png"
            :class="{ 'fade-in-out': isAnimating3 }"
          />
        </div>
        <div class="swiper-button-prev" id="character-pcswiper-button-prev">
          <img src="~@/assets/images/english/pc/part4/arrow1.png" class="fade-in-out" />
          <img
            src="~@/assets/images/english/pc/part4/arrow2.png"
            :class="{ 'fade-in-out': isAnimating2 }"
          />
          <img
            src="~@/assets/images/english/pc/part4/arrow3.png"
            :class="{ 'fade-in-out': isAnimating3 }"
          />
        </div>
      </div>

      <div class="yd" v-if="isWideScreen">
        <videoPopup ref="videoPopup"></videoPopup>
        <!-- 主要内容 -->
        <div class="main-content">
          <!-- 标题 -->
          <div class="title">
            <img
              :src="require(`@/assets/images/${language}/mob/part4/title.png`)"
              alt=""
            />
          </div>
          <!-- Swiper -->
          <div class="swiper mySwiper2">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in 8" :key="index">
                <img
                  :src="
                    require(`@/assets/images/${language}/mob/part4/interface/character${
                      index + 1
                    }.png`)
                  "
                />
                <p class="expansion text" :class="{ 'alabo-text': language === 'alabo' }">
                  {{ charactersText(index) }}
                </p>
                <!-- 视频播放 -->
                <div class="expansion audio">
                  <video
                    class="introduction"
                    muted="true"
                    loop
                    autoplay
                    :ref="'videoyd' + index"
                    v-if="index !== 1"
                  >
                    <source
                      :src="require(`@/assets/images/introduction${index + 1}.mp4`)"
                      type="video/mp4"
                    />
                  </video>
                  <img
                    class="play"
                    v-if="index !== 1"
                    @click="playVideo(index)"
                    src="~@/assets/images/english/mob/part4/play.png"
                    alt=""
                  />
                  <img
                    v-if="index === 1"
                    src="~@/assets/images/english/mob/part4/coming.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div thumbsSlider="" class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in 8" :key="index">
                <img
                  class="unexpansion"
                  :src="
                    require(`@/assets/images/english/mob/part4/unexpansion/character${
                      index + 1
                    }.png`)
                  "
                />
                <img
                  class="expansion active"
                  :src="
                    require(`@/assets/images/english/mob/part4/expansion/character${
                      index + 1
                    }.png`)
                  "
                />
                <img
                  class="expansion checked"
                  src="~@/assets/images/english/mob/part4/checked.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import videoPopup from "./videoPopup.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      isAnimating2: false,
      isAnimating3: false,
      imageUrl: "", // 存储背景图片的URL
      screenWidth: window.innerWidth,
    };
  },
  components: {
    videoPopup,
  },
  computed: {
    ...mapState(["language", "characters"]), // 在这里引入你需要的state值
    isWideScreen() {
      return this.screenWidth <= 980;
    },
  },
  methods: {
    changeVideo() {
      this.$refs.videoPopup.showVideo = !this.$refs.videoPopup.showVideo;
    },

    // 遍历角色介绍的文字
    charactersText(index) {
      return this.characters[`text${index + 1}`];
    },
    // 页面大小变化会触发改函数
    handleResize() {
      this.screenWidth = window.innerWidth;
      this.swiper.update(); // 更新轮播图

      this.swiper.slideTo(0);
    },
    // 页面加载时会触发,并给箭头添加动画效果
    toggleAnimation2() {
      this.isAnimating2 = !this.isAnimating2;
    },
    // 页面加载时会触发,并给箭头添加动画效果
    toggleAnimation3() {
      this.isAnimating3 = !this.isAnimating3;
    },
    // 播放角色视频
    playVideo(index) {
      // const video = this.$refs['video' + index][0]; // 获取对应索引的视频元素
      this.$refs.videoPopup.index = index;
      this.changeVideo();
      // video.requestFullscreen();
      // this.isVideoPlaying = true; // 标记视频正在播放
      // video.muted = false;
      // 设置视频样式为原始大小
    },
    // 移动端播放角色视频
    playVideoyd(index) {
      const video = this.$refs["videoyd" + index][0]; // 获取对应索引的视频元素
      video.requestFullscreen();
      this.isVideoPlaying = true; // 标记视频正在播放
      video.muted = false;
    },
    initSwiper() {
      this.swiper = new Swiper("#character-pcswiper", {
        //  effect: 'fade', // 设置为渐变效果
        slidesPerView: "auto",
        centeredSlides: true,
        slidesOffsetBefore: 530,
        navigation: {
          nextEl: "#character-pcswiper-button-next",
          prevEl: " #character-pcswiper-button-prev",
        },
        // swiper的配置项
        on: {
          init: function () {
            // 页面初始化时添加向左偏移的类名
            document.querySelector("#swiper-wrapper").classList.add("left-offset");
          },
          slideChange: () => {
            if (this.swiper.realIndex === 0) {
              // 切换轮播图时移除向左偏移的类名
              document.querySelector("#swiper-wrapper").classList.add("left-offset");
              console.log(111);
            } else {
              // 切换到其他轮播图时移除类名
              document.querySelector("#swiper-wrapper").classList.remove("left-offset");
            }
          },
        },
      });
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 8,
        slidesPerView: 7,
        freeMode: true,
        watchSlidesProgress: true,
      });
      new Swiper(".mySwiper2", {
        spaceBetween: 10,
        slidesPerView: "auto",
        thumbs: {
          swiper: swiper,
        },
      });
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.exitFullScreenHandler);
    window.addEventListener("resize", this.handleResize);
    setTimeout(this.toggleAnimation2, 1300); // 1.3秒后切换动画状态
    setTimeout(this.toggleAnimation3, 1000); // 1秒后切换动画状态
    this.$nextTick(() => {
      this.initSwiper(); // 初始化 Swiper
      // 根据语言来设置背景图片的URL
      this.imageUrl = require(`@/assets/images/${this.language}/pc/part4/bg4.jpg`);
    });
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.exitFullScreenHandler);
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 980px) {
  .part4 {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }

    .fade-in-out {
      animation: fadeInOut 1.5s infinite;
    }

    .left-offset {
      right: 550px;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-wrapper {
      margin-top: 35px;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 177px !important;

      .expansion {
        display: none;
        margin-bottom: 105px;
      }

      .audio {
        background: url(~@/assets/images/english/pc/part4/play-border.png) center
          no-repeat;
        background-size: cover;
        position: absolute;
        width: 434px;
        height: 244px;
        bottom: 16%;
        right: 11.5%;

        .introduction {
          left: 0;
        }

        .play {
          width: 70px;
          height: 70px;
          margin: 20% 0px 0px -40px;
          cursor: pointer;
          z-index: 999;
          position: absolute;
          // left: 0;
        }
      }

      .alabo-text {
        direction: rtl;
        text-align: right !important;
      }

      img {
        width: 100%;
        object-fit: cover;
      }

      .text {
        color: rgb(196, 195, 195);
        position: absolute;
        width: 457px;
        text-align: left;
        font-size: 20px;
        right: 10.8%;
        top: 39%;
      }
    }

    .swiper-slide-active {
      width: 1265px !important;

      .unexpansion {
        display: none;
      }

      .expansion {
        display: block;
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 204px;
    height: 136px;
    background-size: 100% 100%;
    top: 43%;
    transform: scale(1);
  }

  .swiper-button-next {
    right: 8%;
    margin-bottom: 210px;
    transform: rotateY(180deg);
  }

  .swiper-button-prev {
    left: 8%;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "";
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "";
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
}

@media screen and (max-width: 980px) {
  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .part-main {
    width: 626px;
    height: 1300px;
  }

  .yd {
    .title {
      position: absolute;
      // transform: scale(0.9);
      bottom: 54%;
      left: -18px;

      img {
        width: 100%;
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      top: 300px;

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        .audio {
          background: url(~@/assets/images/english/mob/part4/border.png) center no-repeat;
          background-size: cover;
          position: absolute;
          width: 434px;
          height: 244px;
          bottom: 1%;
          right: -10%;
          transform: scale(0.6);

          .introduction {
            left: 0;
          }

          .play {
            width: 70px;
            height: 70px;
            margin-top: 90px;
            cursor: pointer;
            margin-left: 181px;
            z-index: 999;
            position: absolute;
          }
        }

        .text {
          color: rgb(196, 195, 195);
          position: absolute;
          width: 205px;
          text-align: left;
          font-size: 14px;
          right: 7%;
          top: 39%;
          transform: scale(1.18);
        }
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .mySwiper2 {
    height: 80%;
    width: 100%;
  }

  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
    padding-left: 9px;

    .swiper-slide {
      width: 13% !important;
      height: 100%;

      .checked {
        position: absolute;
        height: 270px;
        width: 88px;
        bottom: -65px;
      }

      .expansion {
        display: none;
      }

      /* opacity: 0.4; */
      img {
        width: 100%;
      }
    }
  }

  .swiper-slide-thumb-active .unexpansion {
    display: none;
  }

  .swiper-slide-thumb-active .expansion {
    display: block !important;
  }

  .swiper-slide-thumb-active .active {
    z-index: 1;
  }

  .swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    border: 1px solid rgb(104 95 95);
  }

  .swiper .swiper-slide .alabo-text {
    direction: rtl;
    text-align: right !important;
  }
}
</style>
