<template>
	<div class="product">
		<div class="product-list">
			<div class="title">
				{{ title }}
			</div>
			<div class="product-main">
				<!-- <div class="product-text" v-html="content"></div> -->
				<div class="product-text">



					<p>
						<strong>At </strong><span style="color:rgb(31,35,41)"><strong>Funcat Games
								Limited,</strong></span><strong> data protection and confidentiality are of the highest
							importance to us.</strong>
					</p>
					<p>
						<strong><br /></strong>
					</p>
					<p>
						This Privacy Policy explains how personal information about you is collected, shared and used by
						<span style="color:rgb(31,35,41)">Funcat Games Limited</span> and how you can exercise your
						privacy rights. This Privacy Policy applies to personal information that we collect when you use
						our mobile game applications, forums, websites and other online products and services
						(collectively, the “Services”) or when you otherwise interact with us.
					</p>
					<p>
						<br />
					</p>
					<p>
						This Privacy Policy governs all our Services.
					</p>
					<p>
						<br />
					</p>
					<p>
						We may amend and/or update this Privacy Policy from time to time by posting a new version in
						response to changing legal, technical or business reasons and developments. If we make material
						changes, we will notify you by revising the date at the top of the notice and, depending on the
						specific amendments, we may provide you with additional notice. We encourage you to review the
						Privacy Policy whenever you access our Services to stay informed about our information practices
						and the ways you can help protect your privacy. Your continued use of the Services shall mean
						your acceptance of the changes to this Privacy Policy.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Information we collect</strong>
							</p>

						</li>
						<li>
							<p>
								<strong>Information you provide to us</strong>
							</p>

						</li>
					</ul>
					<p>
						&nbsp;&nbsp;
					</p>
					<p>
						We collect information you provide voluntarily to us, such as when you participate in a contest
						and promotion, communicate with us via third-party social media sites, request customer support,
						send us an email or otherwise interact with us. The types of information we may collect about
						you include your name, social networking screen names or IDs, email address and any other
						information you choose to provide.
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Information we collect automatically</strong>
							</p>
						</li>
					</ul>
					<p style="text-align: left;">
						&nbsp;
					</p>
					<p style="text-align: left;">
						When you access or use our Services, we automatically collect certain information from you
						and/or your device (which may be considered personal information under applicable data
						protection laws). We collect this information with your either your explicit consent in
						accordance with GDPR article 6(1)(a) or as part of our legitimate interest in accordance with
						GDPR article 6 (1)(f)., including:
					</p>
					<p style="text-align: left;">
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Age</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								when you download our game, we collect your age as indicated by you.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Log Information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								about your use of our Services, including the type of browser you use, access times,
								pages viewed, your IP address, and the page you visited before navigating to our
								Services.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Device information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								about the device you use to access our Services, including information about the
								device’s type, software and hardware, Media Access Control (“MAC”) address and other
								unique device identifiers (such as Device ID and Advertising ID), device token, mobile
								network information and time zone.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Location information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								We collect location information in order to comply with legislation and policies that
								apply to your area. However, neither we nor our partners collect location information
								more granular than city level without your explicit consent.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Usage information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								relating to your use of our Services, including which applications you use and your
								interactions with other users.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Consumption information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								about your consumption habits relating to your use of our Services, including which
								purchases you make with both virtual and real currencies.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Other Information</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								collected by cookies and other tracking technologies: we use various technologies to
								collect information, and this may include sending cookies to your computer or mobile
								device. Cookies are small data files stored on your computer’s hard drive or in device
								memory that help us to improve our Services and your experience and see which areas and
								features of our Services are popular. We may also collect information using web beacons
								(also known as “tracking pixels”). Web beacons are electronic images that may be used in
								our Services or emails and help deliver cookies, count visits, and understand usage and
								campaign effectiveness.
							</p>
							<p>
								<br />
							</p>
						</ul>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Information from third-parties</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet3 list-paddingleft-2" style="list-style-type: square;">
							<p>
								We may also obtain information from other sources and combine that with information we
								collect through our Services. For example, if you log into our Services by using a
								third-party site or platform such as Facebook and any other social media, we may access
								information about you from that site or platform, such as your screen name, profile
								information, including picture, and friend lists, in accordance with the authorisation
								procedures determined by such third-party site or platform. We may also collect
								information from app stores when you download or update one of our applications. We may
								also receive personal information about you from other third-party service providers and
								advertising partners.
							</p>
							<p>
								<br />
							</p>
						</ul>
						<li>
							<p>
								<strong>Information from third-party surveys</strong>
							</p>
						</li>
						<br>
						<ul class="list-bullet2 list-paddingleft-2" style="list-style-type: square;">
							<p>
								We may invite you to participate in surveys facilitated by third-party survey partners.
								From these services, we will collect the information you provide as well as you userID
								in order to be able to match it with your in-game information. You will always be made
								aware that you are being transferred to the third-party service before the collection
								starts and thus have the opportunity to opt out. The partners policies will be made
								available to you and we encourage you to review them before participating in the survey.
							</p>
							<p>
								<br />
							</p>
						</ul>
						<li>
							<p>
								<strong>Ad Networks</strong>
							</p>
							<p></p>
							<p></p>
						</li>
					</ul>
					<ul class="list-bullet1 list-paddingleft-2"></ul>
					<p>
						&nbsp;&nbsp;
					</p>
					<p>
						We may feature advertising in our Services via showing third-party advertisements in our
						Services, as well as advertise our own Services via third-parties.<br />
					</p>
					<p>
						<br />
					</p>
					<p>
						<span style="color:rgb(31,35,41)">Funcat Games Limited</span> may disclose your personal
						information to advertising networks for the purpose of their direct marketing. Thus, we may send
						your advertising ID and IP address to ad networks to enable our partners to serve appropriate
						ads in accordance with GDPR article 6(1)(f). &quot;Advertising ID&quot; means the Apple
						Identifier for Advertisers (“IDFA”) on Apple, and Google Advertising Identity (“GAID”). These
						are unique identifiers for mobile devices that <span style="color:rgb(31,35,41)">Funcat Games
							Limited</span> and our advertising network partners use for interest-based advertising
						(targeted). They are consistent across all apps and thus allow cross-app tracking. These
						identifiers are used by ad networks for a number of purposes, in addition to targeted
						advertisement, thus we encourage you to check the Privacy Policies of our partners. You can find
						a list of <span style="color:rgb(31,35,41)">Funcat Games Limited</span>’s existing advertising
						networks partners and other third-party service providers by clicking here. These advertising
						networks may be located in countries outside EU/EEA that have less restrictive data protection
						legislation. For such advertising networks in countries outside EU/EEA, the legal basis for the
						transfer is always in accordance with one of the lawful instruments set forth I GDPR article
						44-50. You can opt out of all third-party targeted advertisements, for all apps, using the
						device-level opt our settings on your device. If you use an Apple device, a guide for opting out
						can be found here. If you use an Android device, navigate to the Google Settings page, select
						Ads and then choose to reset your Android Ad ID or opt-out of targeted ads.
					</p>
					<p>
						<br />
					</p>
					<p>
						We may allow third-parties to provide analytics services in connection with our Services. These
						entities may use cookies, web beacons and other tracing technologies to collect information
						about your use of our Services, including your IP address, MAC address, device identifiers,
						software and hardware information, browser information, time zone and usage information. This
						information may be used by <span style="color:rgb(31,35,41)">Funcat Games Limited</span> and
						others to, among other things, analyse and track data, determine the popularity of certain
						content, deliver advertising and content targeted to your interests and better understand your
						activity on our Services.
					</p>
					<p>
						<br />
					</p>
					<p>
						Third-party ad serving and analytics technologies are integrated into our Services, so if you do
						not want to be subject to these technologies, do not use or access our Services.
					</p>
					<p>
						<br />
					</p>
					<p>
						Our Services may also integrate third-party Application Program Interfaces (APIs) and/or SDK’s,
						which allow others to collect and process information about you, including but not limited to
						your geo-location information, to provide you with targeted advertisements, offers and other
						content. You always have the ability to control the collection and use of your geo-location
						information . You may disable location-based services at any time by adjusting the settings of
						your mobile device.
					</p>
					<p>
						<br />
					</p>
					<p>
						Adnetworks may use information about you, such as your advertising ID, to identify users who are
						likely to have an interest in our services and display advertising to them. They may also use
						this information to identify whether you are an existing user of our services and exclude you
						from further advertising of our services.
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>Social media, links to websites, other platforms</strong>
							</p>
							<p></p>
							<p></p>
						</li>
					</ul>
					<p>
						&nbsp;&nbsp;
					</p>
					<p>
						Our Services may offer social sharing features and other integrated tools (such as the Facebook
						“Like” button), which let you share actions you take on our Services with other media, and vice
						versa. The use of such features enables the sharing of information with your friends or the
						public, depending on the settings you establish with the entity that provides the social sharing
						feature and information about your game status and progress, etc. may be showed on these media.
						For more information about the purpose and scope of data collection and processing in connection
						with social sharing features, please visit the privacy policies of the entities that provide
						these features.
					</p>
					<p>
						<br />
					</p>
					<p>
						Our Services may contain links to websites, social media, platforms etc. We are not responsible
						for the contents of the websites, social media, platforms etc. of other companies or for the
						practices of such companies regarding the collection of personal information.
					</p>
					<p>
						&nbsp;
					</p>
					<p>
						If you use Facebook Login for authentication, Facebook may use your personal information for
						their own purposes. In this situation please refer to Facebook’s privacy policy and other
						relevant policies.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong>How we use your information</strong>
							</p>
						</li>
					</ul>
					<br>


					<p>
						We may use information about you for various purposes, including to:
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								Provide, maintain and improve our current Services;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Develop new Services;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Provide and deliver the products and services you request and send you related
								information;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Facilitate communications between users;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Respond to your comments, questions and requests and provide customer service and
								support
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Send you technical notices, updates, security alerts, push notifications and support and
								administrative messages;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Communicate with you about products, services, offers, promotions and events offered by
								<span style="color:rgb(31,35,41)">Funcat Games Limited</span> and others, and provide
								news and information we think will be of interest to you;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Personalise and improve our Services with the use of profiling to provide targeted
								advertisements, content or features;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Process and deliver contest entries and rewards;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Monitor and analyse trends, usage and activities in connection with our Services;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Link or combine with information we get from others to help understand your needs and
								provide you with better service;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								Carry out any other purpose for which the information was collected.
							</p>
							<p>
								<br />
							</p>
						</li>
					</ul>
					<p>
						However, we do not request or intend to collect any “special categories information” such as
						information on health, race, religion, political opinions, sexual preferences or orientation. Be
						cautious when sharing this information about yourselves (or others) in our Services.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> How we may disclose your information</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						We may share information about you as follows or as otherwise described in this Privacy Policy:
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								With other users of our Services;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								In response to a request for information if we believe disclosure is in accordance with
								any applicable legal requirement;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								If we believe your actions are inconsistent with the spirit or language of our user
								agreements or policies, or to protect the rights, property and safety of <span
									style="color:rgb(31,35,41)">Funcat Games Limited</span> or others;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								In connection with, or during negotiations of, any merger, sale of company assets,
								financing or acquisition of all or a portion of our business to another company; and
								with your consent or at your direction.
							</p>
							<p>
								<br />
							</p>
						</li>
					</ul>
					<p>
						We also may share aggregated information or otherwise anonymised information, which cannot
						reasonably be used to identify you.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> International Data Transfers</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						<span style="color:rgb(31,35,41)">Funcat Games Limited</span> operates globally and our Services
						are global by nature. Your personal information collected via and/or by <span
							style="color:rgb(31,35,41)">Funcat Games Limited</span> may be stored anywhere in the world
						and may be transferred to, and processed in, countries other than the country of which you are a
						resident. These countries may have data protection laws that are different from the laws of your
						country.
					</p>
					<p>
						<br />
					</p>
					<p>
						When we transfer your personal information internationally, <span
							style="color:rgb(31,35,41)">Funcat Games Limited</span> takes steps to ensure that adequate
						safeguards are in place to protect your personal information and it is treated securely,
						lawfully and in accordance with this Privacy Policy.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> Data retention</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						We retain your personal information collected as described in this Privacy Policy for as long as
						needed to provide you the Services or we have a legitimate business reason to do so.
					</p>
					<p>
						<br />
					</p>
					<p>
						When we no longer have ongoing legitimate business reason to process your personal information,
						we will delete your data.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> Security</strong>
							</p>
						</li>
					</ul>
					<br>

					<p>
						We have implemented security measures to ensure that our internal procedures meet our high
						security policy standards. Accordingly, we strive to protect the quality and integrity of your
						personal information. This includes encryption of data and use of pseudonymisation, whenever
						applicable.
					</p>
					<br>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> Your data protection rights</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						You are at any time entitled to be informed and/or access the personal information about you
						that we process, but with certain legislative exceptions. You have the right to:
					</p>
					<p>
						<br />
					</p>
					<ul class="">
						<li>
							<p>
								1.object to the collection and further processing of your personal information;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								2.have your personal data updated, rectified, erased or blocked;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								3.receive information about you that you have provided to us;
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								4.opt out of certain uses and disclosures of your personal information; and
							</p>
							<p>
								<br />
							</p>
						</li>
						<li>
							<p>
								5.have your personal information transmitted to another data controller (data
								portability). In order for you to exercise your data protection rights we are obliged to
								verify your identity.
							</p>
							<p>
								<br />
							</p>
						</li>
					</ul>
					<p>
						We can only verify your identity if you are logged in on one of our Services. You will therefore
						only be able to exercise your rights if you log in and follow the steps as explained.
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> Withdrawal of consent</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						You may, at any time, withdraw any consent you have given, and we will no longer process your
						personal information, unless we can continue the processing based on another purpose. If you
						wish to withdraw your consent, you can withdraw it in the game via the settings menu or by
						contacting us using the contact information below.
					</p>
					<p>
						<br />
					</p>
					<ul class="list-bullet1 list-paddingleft-2">
						<li>
							<p>
								<strong> Age, Children</strong>
							</p>
						</li>
					</ul>
					<br>
					<p>
						Our Services are not intended for children.
					</p>
					<p>
						<br />
					</p>
					<p>
						<span style="color:rgb(31,35,41)">Funcat Games Limited</span> has implemented age-gate to its
						games to verify players’ age. Even if you are below the age needed for providing a valid consent
						for targeted advertising, profiling and geolocation, you can continue accessing our Services.
						However, there will be no profiling, targeted advertising or geolocation tracking, Our Services
						will then only contain contextual advertising. We will only collect data, on players under 13
						years of age, (or under 16 in certain jurisdictions in the European Union), when it is needed to
						provide the service and ensure that they are protected in accordance with the applicable privacy
						laws.
					</p>
					<p>
						<span class="lark-record-clipboard"></span>
					</p>
					<p>
						<br />
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				content: "",
				title: "",
			};
		},
		methods: {
			async getPrivacy() {
				await this.$store.dispatch("getPrivacy");
				this.content = this.$store.state.privacyData.Privacy.content;
				this.title = this.$store.state.privacyData.Privacy.title;
			},
		},
		mounted() {

			this.getPrivacy();
			// console.log(this.$store.state.privacyData);
		},
	};
</script>

<style scoped="scoped">
	.product {
		width: 100%;
		margin: auto;
		box-sizing: border-box;
		/* margin-top: -3%; */
		max-width: 1240px;

	}

	.product-list {
		padding: 2%;
		background-color: white;

	}

	.product-list:nth-of-type(1) {
		margin-bottom: 2%;

	}

	.title {
		font-size: 24px;
		font-weight: 600;
		color: rgb(184, 37, 42);
		padding: 1% 0 1% 0;
		border-bottom: 2px solid rgb(184, 37, 42);
	}

	.product-text {
		font-size: 20px;

	}
</style>